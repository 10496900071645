import './Icon.css'

export function Icon({ image, alt = '', style = undefined, layoutClassName = undefined }) {
  return (
    <img
      src={image}
      className={`component ${layoutClassName}`}
      role='presentation'
      {...{ alt, style }}
    />
  )
}
