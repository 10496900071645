import React from 'react'
import { useKeenSlider } from 'keen-slider/react'

export function useKeenSliderRefs({
  slidesNum,
  slidesPerView,
  spacing,
  initialSlide,
  sliderDisabled,
  origin,
  loop,
  interactionTitle,
  ...additionalOptions
}) {
  const [sliderRef, instanceRef] = useKeenSlider({
    disabled: Boolean(sliderDisabled),
    initial: initialSlide,
    loop,
    range: {
      align: true,
    },
    mode: 'free-snap',
    slides: {
      origin,
      number: slidesNum,
      spacing,
      perView: slidesPerView
    },
    ...additionalOptions
  })

  React.useEffect(
    () => {
      instanceRef.current.update()
    },
    [instanceRef]
  )

  return { sliderRef }
}
