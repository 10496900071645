import React from 'react'
import Reveal from 'reveal.js'

import { Heading, Subheading } from '../buildingBlocks/Text'

import { Icon } from '../buildingBlocks/Icon'
import { ImageAutoSlider } from './ImageAutoSlider'

import 'reveal.js/plugin/highlight/monokai.css'
import './Slides.css'

// import me from '../../img/me/me.jpeg'

import linkIcon from  '../../img/link-icon.png'

import codeImg from '../../img/code.png'

import pvda1 from '../../img/me/pvda/1.jpeg'
import pvda2 from '../../img/me/pvda/2.jpeg'
import pvda3 from '../../img/me/pvda/3.jpeg'
import pvda4 from '../../img/me/pvda/4.jpeg'
import pvda5 from '../../img/me/pvda/5.jpeg'
import pvda6 from '../../img/me/pvda/6.jpeg'
import pvda7 from '../../img/me/pvda/7.jpeg'
import pvda8 from '../../img/me/pvda/8.jpeg'
import pvda9 from '../../img/me/pvda/9.jpeg'
import pvda10 from '../../img/me/pvda/10.jpeg'
import pvda11 from '../../img/me/pvda/11.jpeg'
import pvda12 from '../../img/me/pvda/12.jpeg'
import pvda13 from '../../img/me/pvda/13.jpeg'
import pvda14 from '../../img/me/pvda/14.jpeg'
import pvda15 from '../../img/me/pvda/15.jpeg'
import pvda16 from '../../img/me/pvda/16.jpeg'
import pvda17 from '../../img/me/pvda/17.jpeg'
import pvda18 from '../../img/me/pvda/18.jpeg'
import pvda19 from '../../img/me/pvda/19.jpeg'

import logoAlliander from '../../img/logos/logo-alliander.svg'
import logoICM from '../../img/logos/logo-icm.svg'
import logoJumbo from '../../img/logos/logo-jumbo.svg'
import logoKLM from '../../img/logos/logo-klm.svg'
import logoNijntje from '../../img/logos/logo-nijntje-museum.svg'
import logoRabobank from '../../img/logos/logo-rabobank.svg'
import logoRituals from '../../img/logos/logo-rituals.svg'
import logoSanAccent from '../../img/logos/logo-san-accent.svg'
import logoSpoorweg from '../../img/logos/logo-spoorwegmuseum.svg'

import codeJobsGrid from '../../img/code-jobs-grid.png'
import codePreloadingPages from '../../img/code-preloading-pages.png'
import codeExpandingMenu from '../../img/code-expanding-menu.png'

export function Me() {
    return (
        <section>
            <section id='me' data-auto-animate>
                <div className="slideMe">
                    <div className="row">
                        <Heading>Hi,&nbsp;</Heading>
                    </div>
                </div>
            </section>

            <section id='me' data-auto-animate>
                <div className="slideMe">
                    <div className="row">
                        <Heading>Hi,&nbsp;</Heading>
                        <Heading><span className='smallText'>I'm a politically engaged high school drop-out.</span></Heading>
                    </div>
                </div>
            </section>

            <section id='me'>
                <Heading centered>Vragen?</Heading>
            </section>

            <section id='me'>
                <Heading centered>Nee, geintje.</Heading>
            </section>

            {/* <section data-auto-animate>
                <div className="slideMe">
                    <div className="imageMe">
                        <Icon image={me} />
                    </div>
                    <div className="column">
                        <Heading>Hi, <span className='smallText'>how are ya?</span></Heading>
                    </div>
                </div>
            </section> */}

            {/* <section data-auto-animate>
                <div className="slideMe">
                    <div className="imageMe">
                        <Icon image={me} />
                    </div>
                    <div className="column">
                        <Heading>Hi, <span className='smallText'>how are ya?</span></Heading>
                        <Subheading>Nice to meet you.</Subheading>
                    </div>
                </div>
            </section> */}
        </section>
    )
}

export function Dropout() {
    return (
        <section id='dropout' data-state='trigger'>
            <section data-auto-animate>
                <Heading>2016</Heading>
            </section>

            <section id='dropout' data-auto-animate>
                <Heading>2016</Heading>
                <Subheading>Vrijstelling leerplicht</Subheading>
            </section>

            <section id='dropout' data-auto-animate>
                <Heading>2016</Heading>

                <div style={{ height: '64px' }} className="imageContainer">
                    <Icon image={codeImg} />
                </div>
                {/* <Heading style={{ color: '#ff3399' }}>{'<HTML> .CSS JS( )'}</Heading> */}

                <Subheading style={{ marginTop: '64px' }}>Vrijstelling leerplicht</Subheading>
            </section>
        </section>
    )
}

export function Politics({ currentSlide }) {
    return (
        <section>
            <section id='politics' data-auto-animate>
                <Heading>2019</Heading>
            </section>

            <section id='politics' data-auto-animate>
                <Heading>2019</Heading>
                <Subheading>Politics</Subheading>
            </section>

            <section id='politics' data-auto-animate>
                <Heading>2019</Heading>
                <ImageAutoSlider images={[pvda1, pvda2, pvda3, pvda4, pvda5, pvda6, pvda7, pvda8, pvda9, pvda10, pvda11, pvda12, pvda13, pvda14, pvda15, pvda16, pvda17, pvda18, pvda19, pvda9, pvda10, pvda1, pvda2, pvda3, pvda4, pvda5, pvda6, pvda7, pvda8, pvda9, pvda10, pvda11, pvda12, pvda13, pvda14, pvda15, pvda16, pvda17, pvda18, pvda19, pvda9, pvda10]} />
                <Subheading style={{ marginTop: '64px' }}>Politics</Subheading>
            </section>
        </section>
    )
}

export function Kaliber() {
    return (
        <section>
            <section data-auto-animate>
                <Heading centered>2023 - 2024 <span className='smallText'>Kaliber</span></Heading>
            </section>

            <section data-auto-animate>
                <Heading centered>2023 - 2024 <span className='smallText'>Kaliber</span></Heading>
                <div className="projectsGrid">
                    <Icon image={logoKLM} />
                    <Icon image={logoRabobank} />
                    <Icon image={logoNijntje} />
                </div>
                <div className="projectsGrid">
                    <Icon image={logoAlliander} />
                    <Icon image={logoICM} />
                    <Icon image={logoJumbo} />
                </div>
                <div className="projectsGrid">
                    <Icon image={logoSanAccent} />
                    <Icon image={logoSpoorweg} />
                    <Icon image={logoRituals} />
                </div>
            </section>

            <section data-auto-animate>
                <Heading centered>2023 - 2024 <span className='smallText'>Kaliber</span></Heading>
                <div className="projectsGrid">
                    <Icon image={logoKLM} />
                    <Icon image={logoRabobank} />
                    <Icon image={logoNijntje} />
                </div>
                <div className='casesGrid'>
                    <div className='videoContainer'>
                        <video data-autoplay loop src="/img/klm.mp4"></video>
                    </div>
                    <div className='videoContainer'>
                        <video data-autoplay loop src="/img/rabo.mp4"></video>
                    </div>
                    <div className='videoContainer'>
                        <video data-autoplay loop src="/img/nijntje.mp4"></video>
                    </div>
                </div>
            </section>

            {/* KLM */}
            <section data-auto-animate>
                <div className="projectsGrid" style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                    <Icon image={logoKLM} />
                </div>
                <div className='casesGrid' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                    <div className='bigVideoContainer'>
                        <video data-autoplay loop src="/img/klm.mp4"></video>
                    </div>
                </div>
            </section>

            {/* <section data-auto-animate>
                <div className="projectsGrid" style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                    <Icon image={logoKLM} />
                </div>
                <div className='casesGrid' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                    <div style={{ height: '400px' }} className="imageContainer">
                        <Icon image={codeJobsGrid} />
                    </div>
                </div>
            </section>

            <section data-auto-animate>
                <div className="projectsGrid" style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                    <Icon image={logoKLM} />
                </div>
                <div className='row'>
                <div className='bigVideoContainer'>
                    <video data-autoplay loop src="/img/klm-demo.mp4"></video>
                </div>
                <a href='https://careers.klm.com/nl/jobs/' target='_blank' rel='noreferrer'>
                    <div style={{ height: '24px' }} className="imageContainer">
                        <Icon image={linkIcon} />
                    </div>
                </a>
                </div>
            </section> */}

            {/* RABO & CO */}
            <section>
                <div className="projectsGrid" style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                    <Icon image={logoRabobank} />
                </div>
                <div className='casesGrid' style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                    <div className='bigVideoContainer'>
                        <video data-autoplay loop src="/img/rabo.mp4"></video>
                    </div>
                </div>
            </section>
        </section>
    )
}

export function Vragen() {
    return (
        <section>
            <Heading centered>Vragen?</Heading>
        </section>
    )
}

