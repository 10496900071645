import React from 'react'
import Reveal from 'reveal.js'
import RevealHighlight from 'reveal.js/plugin/highlight/highlight.js'

import { Me, Dropout, Politics, Kaliber, Vragen } from './features/pageOnly/Slides'

import 'reveal.js/dist/reveal.css'
import './App.css'

export default function App() {
    const deckElementRef = React.useRef(null)
    const deckRef = React.useRef(null)

    const [currentSlide, setCurrentSlide] = React.useState('')

    React.useEffect(
        () => {
            if (deckRef.current) return

            deckRef.current = new Reveal(deckElementRef.current, {
                transition: 'slide',
                viewDistance: 25,
                plugins: [ RevealHighlight ]
            })

            deckRef.current.initialize().then(() => {
                deckRef.current.on('slidechanged', event => {
                    setCurrentSlide(event.currentSlide?.id)
                    
                    // console.log(`Slide changed: from ${event.previousSlide} to ${event.currentSlide}, horizontal index ${event.indexh}, vertical index ${event.indexv}`)
                    // console.log(event.currentSlide?.id)
                })
            })
    

            return () => {
                try {
                    if (deckRef.current) {
                        deckRef.current.destroy()
                        deckRef.current = null
                    }
                } catch (e) {
                    console.warn('Reveal.js destroy call failed.')
                }
            }
        },
        []
    )

    return (
        <div ref={deckElementRef} className='reveal'>
            <div className='slides'>
                <Me />
                <Dropout />
                <Politics {...{ currentSlide }} />
                <Kaliber />
                <Vragen />
            </div>
        </div>
    );
}
