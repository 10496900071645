import React from 'react'

import { useKeenSliderRefs } from '../../machinery/useKeenSliderRefs'

import { Icon } from '../buildingBlocks/Icon'

import styles from './ImageAutoSlider.css'
import 'keen-slider/keen-slider.min.css'

export function ImageAutoSlider({ images }) {
  const animation = { duration: 30000, easing: (t) => t }

  const { sliderRef } = useKeenSliderRefs({
    slidesNum: images?.length,
    slidesPerView: 'auto',
    spacing: 0,
    initialSlide: 6,
    sliderDisabled: false,
    origin: 'center',
    loop: true,
    interactionTitle: 'team-members-slider',
    created(s) { s.moveToIdx(5, true, animation) },
    updated(s) { s.moveToIdx(s.track.details.abs + 5, true, animation) },
    animationEnded(s) { s.moveToIdx(s.track.details.abs + 5, true, animation) },
  })

  return (
      <div ref={sliderRef} className='slider keen-slider'>
        {images?.map((image, i) => (
          <div key={i} className='slide keen-slider__slide'>
            {<ImageSlide layoutClassName={styles.slideLayout} {...{ image }} />}
          </div>
        ))}
      </div>
  )
}

function ImageSlide({ image, layoutClassName = undefined }) {
  return (
    <div className='componentImageSlide'>
      <Icon {...{ image, layoutClassName }} />
    </div>
  )
}
